import *  as React from "react"
import SecondHeader from "../components/second-header";
import Footer from "../components/footer";
import Seo from "../components/seo"

import '../css/index.css';
import { useState, useEffect } from "react";
import Carousel from 'react-bootstrap/Carousel';

// components
import Portada from "../components/Portafolios/Portada";
import Person from "../components/Portafolios/Person";
import SaveImg from "../components/Global/SaveImg";
// conecction firebase
import { store } from '../components/Firebase/firebase-config';

const Portafolios = () => {

    const [editorial, setEditorial] = useState(false);
    const [multimedia, setMultimedia] = useState(false);
    const [ilustration, setIlustration] = useState(false);
    const [branding, setBranding] = useState(false);
    const [sponsor, setSponsor] = useState(false);
    const [uxUi, setUxUi] = useState(false);
    const [c3d, setC3d] = useState(false);
    const [colorBranding, setColorBranding] = useState('white');
    const [colorSponsor, setColorSponsor] = useState('white');
    const [colorMultimedia, setColorMultimedia] = useState('white');
    const [colorUxUi, setColorUxUi] = useState('white');
    const [colorC3d, setColorC3d] = useState('white');

    const [students, setStudents] = useState(null);
    const [blockStudent, setBlockStudent] = useState('');
    const [lastStudent, setLastStudent] = useState(-1);
    const [result, setResult] = useState(0);

    const [filter, setFilter] = useState();
  
    const getStudents = async () => {
        if(localStorage.getItem('inicializadoV5') !== null){
            setResult((JSON.parse(localStorage.getItem('students'))).length)
            const newData = randomList(JSON.parse(localStorage.getItem('students')));
            setStudents(newData);
            setBlockStudent(newData);
            console.log(newData)
        }else{
            localStorage.setItem('inicializadoV5', true);
            const dbRef = store.collection('students');
            const data = await dbRef.get();
            const list = [];
            data.forEach(doc => {
                var element = doc.data();
                var id = doc.id;
                var newElement = {id, ...element}
                list.push(newElement);
            });
            setResult(list.length);
            const newData = randomList(list);
            console.log(newData);
            setStudents(newData);
            setBlockStudent(newData);
            localStorage.setItem('students',JSON.stringify(newData));
            localStorage.setItem('students',JSON.stringify(newData));
            localStorage.setItem('blockStudents',JSON.stringify(newData));
        }
    };

    
    const searchStudentsForName = () => {
        const text = filter.toLowerCase();
        if(text !== ''){
            const newList = blockStudent.filter(function(element){
                if(element!== null){
                    var concat = (element.name.toLowerCase()+element.lastname.toLowerCase()).trim();
                    return (concat).indexOf(text) !== -1;
                }
            });
            setResult(newList.length);
            setStudents(newList);
        }else{
            setResult(blockStudent.length);
            setStudents(blockStudent);
        }
    };

    const filterStudentsForTags = (value, status) => {
        let allTagList = [];
        let s3d = c3d , silu = ilustration, sbra = branding, sspo =sponsor, smul = multimedia, sux = uxUi, sedi = editorial;
        switch (value) {
            case 'ux/ui':
                sux = status;    
            break;
            case 'branding':
                sbra = status;    
            break;
            case 'ilustration':
                silu = status;    
            break;
            case '3d':
                s3d = status;    
            break;
            case 'multimedia':
                smul = status;    
            break;
            case 'editorial':
                sedi = status;    
            break;
            case 'sponsor':
                sspo = status;    
            break;
        
            default:
                break;
        }
        if(uxUi || value === 'ux/ui'){
            if(sux){
                const filterTag = 'ux/ui';
                const newList = blockStudent.filter(function(element){
                    return((element.tags[0]).toLowerCase()).indexOf(filterTag) !== -1;
                });
                newList.map((item)=>{
                    allTagList.push(item);
                });
            }
        }
        if(branding || value === 'branding'){
            if(sbra){
                const filterTag = 'branding';
                const newList = blockStudent.filter(function(element){
                    return((element.tags[0]).toLowerCase()).indexOf(filterTag) !== -1;
                });
                newList.map((item)=>{
                    allTagList.push(item);
                });
            }

        }
        if(ilustration || value === 'ilustration'){
            if(silu){
                const filterTag = 'ilustracion';
                const newList = blockStudent.filter(function(element){
                    return((element.tags[0]).toLowerCase()).indexOf(filterTag) !== -1;
                });
                newList.map((item)=>{
                    allTagList.push(item);
                });
            }

        }
        if(ilustration || value === 'ilustration'){
            if(silu){
                const filterTag = 'ilustración';
                const newList = blockStudent.filter(function(element){
                    return((element.tags[0]).toLowerCase()).indexOf(filterTag) !== -1;
                });
                newList.map((item)=>{
                    allTagList.push(item);
                });
            }

        }
        if(c3d || value === '3d'){
            if(s3d){
                const filterTag = '3d';
                const newList = blockStudent.filter(function(element){
                    return((element.tags[0]).toLowerCase()).indexOf(filterTag) !== -1;
                });
                newList.map((item)=>{
                    allTagList.push(item);
                });
            }

        }
        if(multimedia || value === 'multimedia'){
            if(smul){
                const filterTag = 'multimedia';
                const newList = blockStudent.filter(function(element){
                    return((element.tags[0]).toLowerCase()).indexOf(filterTag) !== -1;
                });
                newList.map((item)=>{
                    allTagList.push(item);
                });
            }

        }
        if(editorial || value === 'editorial'){
            if(sedi){
                const filterTag = 'editorial';
                const newList = blockStudent.filter(function(element){
                    return((element.tags[0]).toLowerCase()).indexOf(filterTag) !== -1;
                });
                newList.map((item)=>{
                    allTagList.push(item);
                });
            }

        }
        if(sponsor || value === 'sponsor'){
            if(sspo){
                const filterTag = 'publicidad';
                const newList = blockStudent.filter(function(element){
                    return((element.tags[0]).toLowerCase()).indexOf(filterTag) !== -1;
                });
                newList.map((item)=>{
                    allTagList.push(item);
                });
            }

        }

        if(allTagList.length === 0){
            setStudents(blockStudent);
            setResult(blockStudent.length);
        }else{
            setStudents(allTagList);
            setResult(allTagList.length);
        }
        // const newAllTagList.map
    };

    useEffect(()=>{
        getStudents();
    },[0])

    function StudentList(){
        let fin = lastStudent + 9;
        if(students !== null){
            const list = students.map((student, key)=>{
                if(key  > lastStudent && key <= fin){
                    return(<>
                        <div 
                            className="col-12 col-sm-12 col-md-4 col-lg-4"
                            onClick={()=>{
                                window.location = `/detalle-portafolio/${student.id}`;
                            }}
                            >
                            <div className="">
                                <div className="row justify-content-center">
                                    <div className="col-md-12">
                                            <Portada student={student}/>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-md-12">
                                        <div className="row justify-content-center">
                                            <div className="col-2 col-sm-2 col-md-4 col-lg-3 col-xl-2">
                                                <Person student={student}/>
                                            </div>
                                            <div className="col-10 col-sm-10 col-md-8 col-lg-9 col-xl-10">
                                                <p className="font-family-archivo-bold title-portafolio"><b>{(student.name).charAt(0).toUpperCase() + (student.name).slice(1)} {(student.lastname).charAt(0).toUpperCase() + (student.lastname).slice(1)}</b></p>
                                                <p className="subtitle-portafolio font-family-archivo">{student.tags[0] === 'Ilustracion' ? 'Ilustración' : student.tags[0]}</p>
                                            </div>        
                                        </div>
                                    </div>
                                    
                                </div>

                            </div>
                        </div>
                    </>);
                }
            })
            return(list);
        }
        return(<>
            <div className="col-md-4">
                <div className="">
                    <div className="row">
                        <div className="col-md-12">
                            <p className="text-center">
                                <img 
                                    src='https://firebasestorage.googleapis.com/v0/b/mad-udb.appspot.com/o/img%2Ffile-img.png?alt=media&token=1dd26735-b8a2-4ce9-9df5-a3b153bc14b7'
                                    alt=""
                                    className="img-portafolio" 
                                />
                            </p>
                        </div>
                    </div>
                    <div    
                        className="row">
                        <div className="col-2 col-sm-2 col-md-2 my-2 mx-1">
                            <img 
                                src='https://firebasestorage.googleapis.com/v0/b/mad-udb.appspot.com/o/img%2Fuser-default.png?alt=media&token=9b12c869-dce3-429b-be12-ca5c026b2395'
                                alt="" 
                                className="img-ilustrador"
                            />
                        </div>
                        <div className="col-8 col-sm-8 col-md-8">
                            <p className="font-family-archivo-bold title-portafolio"><b>Cargando..</b></p>
                            <p className="subtitle-portafolio font-family-archivo">Cargando...</p>
                        </div>
                    </div>

                </div>
            </div>
            <div className="col-md-4">
                <div className="">
                    <div className="row">
                        <div className="col-md-12">
                            <p className="text-center">
                                <img 
                                    src='https://firebasestorage.googleapis.com/v0/b/mad-udb.appspot.com/o/img%2Ffile-img.png?alt=media&token=1dd26735-b8a2-4ce9-9df5-a3b153bc14b7'
                                    alt=""
                                    className="img-portafolio" 
                                />
                            </p>
                        </div>
                    </div>
                    <div    
                        className="row">
                        <div className="col-2 col-sm-2 col-md-2 my-2 mx-1">
                            <img 
                                src='https://firebasestorage.googleapis.com/v0/b/mad-udb.appspot.com/o/img%2Fuser-default.png?alt=media&token=9b12c869-dce3-429b-be12-ca5c026b2395'
                                alt="" 
                                className="img-ilustrador"
                            />
                        </div>
                        <div className="col-8 col-sm-8 col-md-8">
                            <p className="font-family-archivo-bold title-portafolio"><b>Cargando..</b></p>
                            <p className="subtitle-portafolio font-family-archivo">Cargando...</p>
                        </div>
                    </div>

                </div>
            </div>
            <div className="col-md-4">
                <div className="">
                    <div className="row">
                        <div className="col-md-12">
                            <p className="text-center">
                                <img 
                                    src='https://firebasestorage.googleapis.com/v0/b/mad-udb.appspot.com/o/img%2Ffile-img.png?alt=media&token=1dd26735-b8a2-4ce9-9df5-a3b153bc14b7'
                                    alt=""
                                    className="img-portafolio" 
                                />
                            </p>
                        </div>
                    </div>
                    <div    
                        className="row">
                        <div className="col-2 col-sm-2 col-md-2 my-2 mx-1">
                            <img 
                                src='https://firebasestorage.googleapis.com/v0/b/mad-udb.appspot.com/o/img%2Fuser-default.png?alt=media&token=9b12c869-dce3-429b-be12-ca5c026b2395'
                                alt="" 
                                className="img-ilustrador"
                            />
                        </div>
                        <div className="col-8 col-sm-8 col-md-8">
                            <p className="font-family-archivo-bold title-portafolio"><b>Cargando..</b></p>
                            <p className="subtitle-portafolio font-family-archivo">Cargando...</p>
                        </div>
                    </div>

                </div>
            </div>
            <div className="col-md-4">
                <div className="">
                    <div className="row">
                        <div className="col-md-12">
                            <p className="text-center">
                                <img 
                                    src='https://firebasestorage.googleapis.com/v0/b/mad-udb.appspot.com/o/img%2Ffile-img.png?alt=media&token=1dd26735-b8a2-4ce9-9df5-a3b153bc14b7'
                                    alt=""
                                    className="img-portafolio" 
                                />
                            </p>
                        </div>
                    </div>
                    <div    
                        className="row">
                        <div className="col-2 col-sm-2 col-md-2 my-2 mx-1">
                            <img 
                                src='https://firebasestorage.googleapis.com/v0/b/mad-udb.appspot.com/o/img%2Fuser-default.png?alt=media&token=9b12c869-dce3-429b-be12-ca5c026b2395'
                                alt="" 
                                className="img-ilustrador"
                            />
                        </div>
                        <div className="col-8 col-sm-8 col-md-8">
                            <p className="font-family-archivo-bold title-portafolio"><b>Cargando..</b></p>
                            <p className="subtitle-portafolio font-family-archivo">Cargando...</p>
                        </div>
                    </div>

                </div>
            </div>
            <div className="col-md-4">
                <div className="">
                    <div className="row">
                        <div className="col-md-12">
                            <p className="text-center">
                                <img 
                                    src='https://firebasestorage.googleapis.com/v0/b/mad-udb.appspot.com/o/img%2Ffile-img.png?alt=media&token=1dd26735-b8a2-4ce9-9df5-a3b153bc14b7'
                                    alt=""
                                    className="img-portafolio" 
                                />
                            </p>
                        </div>
                    </div>
                    <div    
                        className="row">
                        <div className="col-2 col-sm-2 col-md-2 my-2 mx-1">
                            <img 
                                src='https://firebasestorage.googleapis.com/v0/b/mad-udb.appspot.com/o/img%2Fuser-default.png?alt=media&token=9b12c869-dce3-429b-be12-ca5c026b2395'
                                alt="" 
                                className="img-ilustrador"
                            />
                        </div>
                        <div className="col-8 col-sm-8 col-md-8">
                            <p className="font-family-archivo-bold title-portafolio"><b>Cargando..</b></p>
                            <p className="subtitle-portafolio font-family-archivo">Cargando...</p>
                        </div>
                    </div>

                </div>
            </div>
            <div className="col-md-4">
                <div className="">
                    <div className="row">
                        <div className="col-md-12">
                            <p className="text-center">
                                <img 
                                    src='https://firebasestorage.googleapis.com/v0/b/mad-udb.appspot.com/o/img%2Ffile-img.png?alt=media&token=1dd26735-b8a2-4ce9-9df5-a3b153bc14b7'
                                    alt=""
                                    className="img-portafolio" 
                                />
                            </p>
                        </div>
                    </div>
                    <div    
                        className="row">
                        <div className="col-2 col-sm-2 col-md-2 my-2 mx-1">
                            <img 
                                src='https://firebasestorage.googleapis.com/v0/b/mad-udb.appspot.com/o/img%2Fuser-default.png?alt=media&token=9b12c869-dce3-429b-be12-ca5c026b2395'
                                alt="" 
                                className="img-ilustrador"
                            />
                        </div>
                        <div className="col-8 col-sm-8 col-md-8">
                            <p className="font-family-archivo-bold title-portafolio"><b>Cargando..</b></p>
                            <p className="subtitle-portafolio font-family-archivo">Cargando...</p>
                        </div>
                    </div>

                </div>
            </div>
        </>)
    };

    function Selected(props){
        const state = props.state;
        const color = props.color;
        if(state){
            if(color === 'white'){
                return(
                    <img 
                        src="https://firebasestorage.googleapis.com/v0/b/mad-udb.appspot.com/o/img%2Fremove.png?alt=media&token=82c0641f-6328-425a-bbe4-a9e18ec9f3fd" 
                        alt="" 
                        className="badge-icon"
                    />
                )
            }
            return(
                <img 
                    src="https://firebasestorage.googleapis.com/v0/b/mad-udb.appspot.com/o/img%2Fremove-dark.png?alt=media&token=22e4eb1f-9b28-447e-bbab-63afcfb9438d" 
                    alt="" 
                    className="badge-icon"
                />
            )
        }
        return;
    }

    function UncontrolledExample() {
        return (
          <Carousel indicatorLabels={[]}>
            <Carousel.Item>
                <div className="py-1 py-md-5 py-lg-5">
                    <button 
                        className={
                            (!editorial) ? "badge bg-white text-black border-radius-bagde px-3 py-1 mx-1 border-none bg-color-editorial-hover" 
                            : 'badge border-radius-bagde px-3 py-1 mx-1 bg-color-editorial text-dark bg-select-hover border-editorial'}
                        onClick={()=>{
                            filterStudentsForTags('editorial', !editorial);
                            setEditorial((!editorial));
                        }}
                        >
                        Editorial
                        <Selected state={editorial} color='dark'/>
                    </button>
                    <button 
                        className={
                            (!multimedia) ? "badge bg-white text-black border-radius-bagde px-3 py-1 mx-1 border-none bg-color-multimedia-hover"
                            : 'badge border-radius-bagde px-3 py-1 mx-1 bg-color-multimedia text-white bg-select-hover border-multimedia'}
                        onClick={()=>{
                            filterStudentsForTags('multimedia', !multimedia);
                            setMultimedia((!multimedia));
                        }}
                        onMouseEnter={()=>{
                            setColorMultimedia('dark');
                        }}
                        onMouseLeave={()=>{
                            setColorMultimedia('white');
                        }}
                        >
                        Multimedia
                        <Selected state={multimedia} color={colorMultimedia}/>
                    </button>
                    <button 
                        className={
                            (!ilustration) ? "badge bg-white text-black border-radius-bagde px-3 py-1 my-1 mx-1 border-none bg-color-ilustration-hover"
                            : 'badge border-radius-bagde px-3 py-1 mx-1 bg-color-ilustration text-dark bg-select-hover border-ilustration'
                        }
                        onClick={()=>{
                            filterStudentsForTags('ilustration', !ilustration);
                            setIlustration((!ilustration));
                        }}
                    >
                        Ilustración
                        <Selected state={ilustration} color='dark'/>
                    </button>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className="py-1 py-md-5 py-lg-5">
                    
                    <button 
                        className={
                            (!branding) ? "badge bg-white text-black border-radius-bagde px-3 py-1 my-1 mx-1 border-none bg-color-branding-hover"
                            : 'badge border-radius-bagde px-3 py-1 mx-1 bg-color-branding text-white bg-select-hover border-branding'
                        }
                        onClick={()=>{
                            setLastStudent(-1);
                            filterStudentsForTags('branding', !branding);
                            setBranding((!branding));
                        }}
                        onMouseEnter={()=>{
                            setColorBranding('dark');
                        }}
                        onMouseLeave={()=>{
                            setColorBranding('white');
                        }}
                        >
                            Branding
                            <Selected state={branding} color={colorBranding}/>
                    </button>
                    <button 
                        className={
                            (!sponsor) ? "badge bg-white text-black border-radius-bagde px-3 py-1 my-1 mx-1 border-none bg-color-sponsor-hover"
                            : 'badge border-radius-bagde px-3 py-1 mx-1 bg-color-sponsor text-white bg-select-hover border-sponsor'
                        }
                        onClick={()=>{
                            filterStudentsForTags('sponsor', !sponsor);
                            setSponsor((!sponsor));
                        }}
                        onMouseEnter={()=>{
                            setColorSponsor('dark');
                        }}
                        onMouseLeave={()=>{
                            setColorSponsor('white');
                        }}
                    >
                        Publicidad
                        <Selected state={sponsor} color={colorSponsor}/>
                    </button>
                    <button 
                        className={
                            (!uxUi) ? "badge bg-white text-black border-radius-bagde px-3 py-1 my-1 mx-1 border-none bg-color-ux-ui-hover"
                            : 'badge border-radius-bagde px-3 py-1 mx-1 bg-color-ux-ui text-white bg-select-hover border-ux-ui'
                        }
                        onClick={()=>{
                            filterStudentsForTags('ux/ui', !uxUi);
                            setUxUi((!uxUi));
                        }}
                        onMouseEnter={()=>{
                            setColorUxUi('dark');
                        }}
                        onMouseLeave={()=>{
                            setColorUxUi('white');
                        }}
                    >
                        UX/UI
                        <Selected state={uxUi} color={colorUxUi}/>
                    </button>
                    <button 
                        className={
                            (!c3d) ? "badge bg-white text-black border-radius-bagde px-3 py-1 my-1 mx-1 border-none bg-color-3d-hover"
                            : 'badge border-radius-bagde px-3 py-1 mx-1 bg-color-3d text-white bg-select-hover border-3d'
                        }
                        onClick={()=>{
                            filterStudentsForTags('3d', !c3d);
                            setC3d((!c3d));
                        }}
                        onMouseEnter={()=>{
                            setColorC3d('dark');
                        }}
                        onMouseLeave={()=>{
                            setColorC3d('white');
                        }}
                    >
                        3D
                        <Selected state={c3d} color={colorC3d}/>
                    </button>
                </div>
            </Carousel.Item>
          </Carousel>
        );
    }

    function Pagination(){
        if(students !== null){
            if(students.length > 9){
                return(
                    <div className="row">
                        <div className="col-6 p-md-4 p-lg-4 py-2">
                            <div className="row">
                                <button 
                                    className="btn background-dark text-white  btn-sm font-family-archivo btn-pagination"
                                    style={{borderRadius: '15px'}}
                                    onClick={()=>{
                                        if(lastStudent > -1){
                                            setLastStudent(lastStudent-9);
                                        }
                                    }}
                                >Anterior</button>
                            </div>
                        </div>
                        <div className="col-6 p-md-4 p-lg-4 py-2">
                            <div className="row">
                                <button 
                                    className="btn background-dark text-white  btn-sm font-family-archivo btn-pagination"
                                    style={{borderRadius: '15px'}}
                                    onClick={()=>{
                                        var final = result -9;
                                        if(lastStudent < final)
                                        setLastStudent(lastStudent+9);
                                    }}
                                >Siguiente</button>
                            </div>
                        </div>
                    </div>
                );
            }
        }
        return(<></>);
    }

    const randomList = (dataStudents) => {
        const data = dataStudents;
        const newList = [];
        let n = 0;
        while (n < 107) {
            var position =  Math.floor(Math.random() * ((data.length - 1)-0 +1)) + 0;
            if(data[position] !== null && data[position] !== undefined){
                newList.push(data[position]);
                data.splice(position, 1);
            }
            n++;
        }
        return newList;
    };

    return(
        <>
            {/* <SaveImg /> */}
            <SecondHeader />
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-8 col-lg-8 font-family-archivo-semibold my-5">
                        <div className="row d-block d-sm-block d-md-none d-lg-none">
                            <UncontrolledExample />
                        </div>
                        <div className="row d-none d-sm-none d-md-block d-lg-block">
                            <div className="col-12">
                                <button 
                                    className={
                                        (!editorial) ? "badge bg-white text-black border-radius-bagde px-3 py-1 mx-1 border-none bg-color-editorial-hover" 
                                        : 'badge border-radius-bagde px-3 py-1 mx-1 bg-color-editorial text-dark bg-select-hover border-editorial'}
                                    onClick={()=>{
                                        setLastStudent(-1);
                                        filterStudentsForTags('editorial', !editorial);
                                        setEditorial((!editorial));
                                    }}
                                    >
                                    Editorial
                                    <Selected state={editorial} color='dark'/>
                                </button>
                                <button 
                                    className={
                                        (!multimedia) ? "badge bg-white text-black border-radius-bagde px-3 py-1 mx-1 border-none bg-color-multimedia-hover"
                                        : 'badge border-radius-bagde px-3 py-1 mx-1 bg-color-multimedia text-white bg-select-hover border-multimedia'}
                                    onClick={()=>{
                                        setLastStudent(-1);
                                        filterStudentsForTags('multimedia', !multimedia);
                                        setMultimedia((!multimedia));
                                    }}
                                    onMouseEnter={()=>{
                                        setColorMultimedia('dark');
                                    }}
                                    onMouseLeave={()=>{
                                        setColorMultimedia('white');
                                    }}
                                    >
                                    Multimedia
                                    <Selected state={multimedia} color={colorMultimedia}/>
                                </button>
                                <button 
                                    className={
                                        (!ilustration) ? "badge bg-white text-black border-radius-bagde px-3 py-1 my-1 mx-1 border-none bg-color-ilustration-hover"
                                        : 'badge border-radius-bagde px-3 py-1 mx-1 bg-color-ilustration text-dark bg-select-hover border-ilustration'
                                    }
                                    onClick={()=>{
                                        setLastStudent(-1);
                                        filterStudentsForTags('ilustration', !ilustration);
                                        setIlustration((!ilustration));
                                    }}
                                >
                                    Ilustración
                                    <Selected state={ilustration} color='dark'/>
                                </button>
                                <button 
                                    className={
                                        (!branding) ? "badge bg-white text-black border-radius-bagde px-3 py-1 my-1 mx-1 border-none bg-color-branding-hover"
                                        : 'badge border-radius-bagde px-3 py-1 mx-1 bg-color-branding text-white bg-select-hover border-branding'
                                    }
                                    onClick={()=>{
                                        setLastStudent(-1);
                                        filterStudentsForTags('branding', !branding);
                                        setBranding((!branding));
                                    }}
                                    onMouseEnter={()=>{
                                        setColorBranding('dark');
                                    }}
                                    onMouseLeave={()=>{
                                        setColorBranding('white');
                                    }}
                                    >
                                        Branding
                                        <Selected state={branding} color={colorBranding}/>
                                </button>
                                <button 
                                    className={
                                        (!sponsor) ? "badge bg-white text-black border-radius-bagde px-3 py-1 my-1 mx-1 border-none bg-color-sponsor-hover"
                                        : 'badge border-radius-bagde px-3 py-1 mx-1 bg-color-sponsor text-white bg-select-hover border-sponsor'
                                    }
                                    onClick={()=>{
                                        setLastStudent(-1);
                                        filterStudentsForTags('sponsor', !sponsor);
                                        setSponsor((!sponsor));
                                    }}
                                    onMouseEnter={()=>{
                                        setColorSponsor('dark');
                                    }}
                                    onMouseLeave={()=>{
                                        setColorSponsor('white');
                                    }}
                                >
                                    Publicidad
                                    <Selected state={sponsor} color={colorSponsor}/>
                                </button>
                                <button 
                                    className={
                                        (!uxUi) ? "badge bg-white text-black border-radius-bagde px-3 py-1 my-1 mx-1 border-none bg-color-ux-ui-hover"
                                        : 'badge border-radius-bagde px-3 py-1 mx-1 bg-color-ux-ui text-white bg-select-hover border-ux-ui'
                                    }
                                    onClick={()=>{
                                        setLastStudent(-1);
                                        filterStudentsForTags('ux/ui', !uxUi);
                                        setUxUi((!uxUi));
                                    }}
                                    onMouseEnter={()=>{
                                        setColorUxUi('dark');
                                    }}
                                    onMouseLeave={()=>{
                                        setColorUxUi('white');
                                    }}
                                >
                                    UX/UI
                                    <Selected state={uxUi} color={colorUxUi}/>
                                </button>
                                <button 
                                    className={
                                        (!c3d) ? "badge bg-white text-black border-radius-bagde px-3 py-1 my-1 mx-1 border-none bg-color-3d-hover"
                                        : 'badge border-radius-bagde px-3 py-1 mx-1 bg-color-3d text-white bg-select-hover border-3d'
                                    }
                                    onClick={()=>{
                                        setLastStudent(-1);
                                        filterStudentsForTags('3d', !c3d);
                                        setC3d((!c3d));
                                    }}
                                    onMouseEnter={()=>{
                                        setColorC3d('dark');
                                    }}
                                    onMouseLeave={()=>{
                                        setColorC3d('white');
                                    }}
                                >
                                    3D
                                    <Selected state={c3d} color={colorC3d}/>
                                </button>
                            </div>
                            <div className="col-12 mx-md-1 mx-lg-1 mt-3">
                                <p className="text-results font-family-archivo">{result} resultados</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-5 my-md-5 my-lg-5">
                        <div className="container">
                            <div className="row mx-lg-1 mx-md-1">
                                <div className="col-9 col-sm-9 col-md-9 col-lg-9">
                                    <div class="input-group input-search">
                                        <span class="input-group-text no-border-rigth bg-white height-40 input-search" id="basic-addon1">
                                            <img 
                                                src="https://firebasestorage.googleapis.com/v0/b/mad-udb.appspot.com/o/img%2Fsearch%20(1).png?alt=media&token=54ec9ccb-cd57-4c2d-ab0d-1742602ab546" 
                                                alt=""
                                                className="icons-input"
                                            />
                                        </span>
                                        <input 
                                            type="text" 
                                            onChange={(e)=>{
                                                setFilter(e.target.value);
                                            }}
                                            class="form-control font-family-archivo height-40 no-border-left input-search" 
                                            placeholder="Busca por nombre"
                                        />
                                    </div>
                                </div>
                                <div className="col-2 col-sm-2 col-md-2 col-lg-2">
                                    <button 
                                        className="btn font-family-archivo-semibold search-button"
                                        onClick={()=>{
                                            setLastStudent(-1);
                                            searchStudentsForName();
                                        }}
                                    >Buscar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center" >
                    <StudentList />
                    <div className="col-10 offset-col-1 offset-col-md-4 offset-col-lg-4">
                        <Pagination />
                        
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export const Head = () => <Seo title="Portafolios" />

export default Portafolios